import React, { useContext, useReducer, useRef, useState } from 'react'
import { GoogleMap } from '../../utils/googlemaps';
import gifAlerta from '../../assets/images/gifalertaNueva.gif'
import { UserContext } from '../../context/provider/UserProvider';
import { AuthFetch } from '../../services/api';
import { notify } from '../../utils/utils';

const urlPlaminApi = process.env.REACT_APP_PLAMIN_API + '/api';

export const useIncidentes = () => {
    const marcadorAlerta = useRef(null)
    const marcadorPolicia = useRef(null)

    const incidentesInicial = {
        incidentes: [],
        incidente: null,
        estadoActual: 9,
        googleMap: new GoogleMap()
    }
    const incidentesTipo = {
        LISTAR_INCIDENTES: 'LISTAR_INCIDENTES',
        OBTENER_INCIDENTE: 'OBTENER_INCIDENTE',
        CAMBIAR_ESTADO: 'CAMBIAR_ESTADO',
    }

    const [jurisdiccionMapa,setJurisdiccionMapa] = useState('')
    const [agentesTotales,setAgentesTotales] = useState([])
    const [positionNuevaIncidencia,setPositionNuevaIncidencia] = useState({})
    const [ incidencias, setIncidencias ] = useState([])

    const incidentesReducer = (state, action) => {
        switch (action.type) {
            case incidentesTipo.LISTAR_INCIDENTES:
                return {
                    ...state,
                    incidentes: action.payload
                }
            case incidentesTipo.OBTENER_INCIDENTE:
                return {
                    ...state,
                    incidente: action.payload
                }
            case incidentesTipo.CAMBIAR_ESTADO:
                return {
                    ...state,
                    estadoActual: action.payload.codEstado
                }
            default:
                throw new Error(`Invalid action type`);
        }
    }

    const [ stateIncidentes, dispatchIncidentes ] = useReducer(incidentesReducer, incidentesInicial);
    const {stateUser} = useContext(UserContext)

    const listarIncidentes = async (codEstado) => {
        const response = await AuthFetch({
            url: urlPlaminApi + '/alerts?' + new URLSearchParams({
                CodEstado: 0,
            })
        });

        if (response.isValid) {
            dispatchIncidentes({ type: incidentesTipo.LISTAR_INCIDENTES, payload: response.content });
        }

        dispatchIncidentes({ type: incidentesTipo.CAMBIAR_ESTADO, payload: { codEstado: codEstado || 0 } });
    }

    const  listarIncidenciasTemp = async (codEstado) => {
        const response = await AuthFetch({
            url: urlPlaminApi + '/alerts?' + new URLSearchParams({
                CodEstado: 0,
            })
        });

        return response.isValid ? response.content : []
        // setIncidencias(response.isValid ? response.content : [])
    }
    
    const ubicarIncidencia = (incidencia,esNuevaIncidencia) => {
        !esNuevaIncidencia && stateIncidentes.googleMap.quitarTodosLosMarcadores()

        marcadorAlerta.current = stateIncidentes.googleMap.crearMarcador({
            animation: esNuevaIncidencia ? true : false,    //la animacion solo cuando es una incidencia reciente
            visible: false,
        });

        stateIncidentes.googleMap.crearMarcadorGif({
            icon: gifAlerta,
            latLng: {lat: incidencia.latitudRegistro || incidencia.latitud, lng: incidencia.longitudRegistro || incidencia.longitud}
        })

        marcadorAlerta.current.setOptions({
            position: {lat: incidencia.latitudRegistro || incidencia.latitud, lng: incidencia.longitudRegistro || incidencia.longitud},
            visible: true
        })

        if(esNuevaIncidencia){
            setPositionNuevaIncidencia({
                lat: incidencia.latitud,
                lng: incidencia.longitud
            })
        }else{
            setPositionNuevaIncidencia({})
        }

        marcadorAlerta.current = stateIncidentes.googleMap.marcadores[0]

        stateIncidentes.googleMap.map.setOptions({
            center: {lat: incidencia.latitudRegistro || incidencia.latitud, lng: incidencia.longitudRegistro || incidencia.longitud},
            zoom: 15
        })
    }

    const obtenerJurisdiccionPoligono = async () => {
        const response = await AuthFetch({
            url: urlPlaminApi + '/Entities/jurisdictionByEntity?' + new URLSearchParams({
                codEntidad: stateUser.codEntidad || 0
            })
        });

        const path = response.content[0].poligonoJurisdiccion.split(',').map(latLng => {
            const [ lat, lng ] = latLng.split(';');
            return { lat: Number(lat), lng: Number(lng) };
        })

        const poligonoCreado = stateIncidentes.googleMap.crearPoligono({listLatLng: path , opacidadFondo: 0});
        setJurisdiccionMapa(poligonoCreado)
    }

    const obtenerUbicacionAgentes = async (codAlerta = null) => {
        const parametros = {
            codEntidad : stateUser.codEntidad || 0
        }
        codAlerta && (parametros.codAlerta = codAlerta)
        const response = await AuthFetch({
            url: urlPlaminApi + '/User/agentsTotal?' + new URLSearchParams(parametros),
        })

        if(response.isValid) {
            return response.content
        }else {
            return []
        }
    }

    const obtenerUbicacionAgentesPorAlerta = async (codAlerta) => {
        const response = await AuthFetch({
            url: urlPlaminApi + '/User/agentsTotal?' + new URLSearchParams({
                codEntidad: stateUser.codEntidad,
                codAlerta: codAlerta,
            })
        })

        if(response.isValid){
            setAgentesTotales(response.content)
        }else{
            notify(response.exceptions[0].description,'error')
        }
    }

    return { 
        stateIncidentes,
        dispatchIncidentes,
        listarIncidentes,
        ubicarIncidencia,
        stateUser,
        positionNuevaIncidencia,
        marcadorAlerta,
        marcadorPolicia,
        obtenerJurisdiccionPoligono,
        jurisdiccionMapa,
        obtenerUbicacionAgentes,
        obtenerUbicacionAgentesPorAlerta,
        agentesTotales,
        listarIncidenciasTemp,
    };
};
