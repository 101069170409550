import React, { useState, useEffect, useContext, useRef, useMemo, useReducer} from 'react';
import { MapaAlertas } from '../../components/pages/incidentes/MapaAlertas';
import { ContenedorParametros } from '../../components/utils/ContenedorParametros';
import { SocketContext } from '../../../context/provider/SocketProvider';
import { classNameInput, notify, primeraLetraMayuscula } from '../../../utils/utils';
import { GoogleMap } from '../../../utils/googlemaps';
import { Modal } from '../../components/modal/Modal';
import { useModal } from '../../../hooks/useModal';
import { AuthFetch } from '../../../services/api';
import icono from '../../../assets/images/logo192.png'
import iconoPolicia from '../../../assets/images/iconoPolicia.png'
import { Tooltip } from '../../components/utils/Tooltip';
import ReactTooltip from 'react-tooltip';
import { useUsuarios } from '../../../hooks/usuarios/useUsuarios';
import { useSeguimientoAlertas } from '../../../hooks/Incidentes/useSeguimientoAlertas';
import { useCalificacionAlerta } from '../../../hooks/Incidentes/useCalificacionAlerta';
import '../../../assets/css/components/googleMapsTest.css'
import botonAlertasCercanas from '../../../assets/images/botones-mapa-incidencias/BtnPlaminMapaVerGrupo.png'
import botonAgentesActivos from '../../../assets/images/botones-mapa-incidencias/BtnPlaminMapaVerAgente.png'
import botonCamarasDisponibles from '../../../assets/images/botones-mapa-incidencias/BtnPlaminMapaVerCamaras.png'
import botonVerMapa from '../../../assets/images/botones-mapa-incidencias/BtnPlaminMapaVerMapa.png'
import camaraSinConexion from '../../../assets/images/camara-sin-señal.gif'

import { ModalAsignacion } from '../../components/modal/ModalAsignacion';
import { useIncidentes } from '../../../hooks/Incidentes/useIncidentes';
import { ComponenteCarga } from '../../components/forms/ComponenteCarga';
import { useSound } from '../../../context/provider/SoundProvider';

const google = window.google;
const googleMap = new GoogleMap();
const urlBasePlamin = process.env.REACT_APP_PLAMIN_API + "/api";

const useFotos = () => {
    const [ fotoUrl, setFotoUrl ] = useState();
    const [ isOpenFoto, openModalFoto, closeModalFoto ] = useModal(false); 

    const verFoto = (nomCarpeta,nombreFoto) => {
        setFotoUrl(url =>`https://minio.api.abexacloud.com/api/Archivo/verImagen?NombreCarpeta=${nomCarpeta}&NombreImagen=${nombreFoto}`);
        openModalFoto();
    }
    return [ fotoUrl, verFoto, isOpenFoto, closeModalFoto ] 
}

export const Incidentes = () => {
    const audioAlerta = useRef(null);
    const eventoMostrarAgentesRef = useRef(null)
    const eventoAgenteAsignadoRef = useRef(null)
    const marcadoresAgentesRef = useRef([])
    const marcadorCamaraRef = useRef(null)
    const incidenteActualRef = useRef({})

    const {stateSocket} = useContext(SocketContext);
    const {sonidoAlertaActual} = useSound()

    const hookIncidentes = useIncidentes()
    const hookSeguimientoAlertas = useSeguimientoAlertas()
    const hookCalificacionAlerta = useCalificacionAlerta()
    const [isOpenModalAgentes, openModalAgentes, closeModalAgentes] = useModal();
    const [fotoUrl, verFoto, isOpenFoto, closeModalFoto] = useFotos();
    const [isOpenModalCamara,openModalCamara,closeModalCamara] = useModal()

    const [incidenteActual, setIncidenteActual] = useState({})
    const [verAgentes, setVerAgentes] = useState(false)
    const [verAgenteAsignado, setVerAgenteAsignado] = useState(false)
    const [usuarioPosteo, setUsuarioPosteo] = useState()
    const [mostrarEfectoOndas, setMostrarEfectoOndas] = useState(false)
    const [waveRadius, setWaveRadius] = useState(200);
    const [isDragging, setIsDragging] = useState(false);
    const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
    const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
    const [rotacion, setRotacion] = useState(0);
    const [zoom, setZoom] = useState(1);
    const [marcadoresCercanos, setMarcadoresCercanos] = useState([])
    const [marcadoresCercanosReferencias, setMarcadoresCercanosReferencias] = useState([])
    const [verMarcadoresCercanos, setVerMarcadoresCercanos] = useState(false)
    const [verPuntosDeReferencia,setVerPuntosDeReferencia] = useState(false)
    const [verCamaras,setVerCamaras] = useState(false)
    const [estaCargando, setEstaCargando] = useState(false)
    const [ubicacionesAgentes,setUbicacionesAgentes] = useState([])

    useEffect(() =>{
        (async () => {
            setEstaCargando(true)
            await hookIncidentes.obtenerJurisdiccionPoligono()
            await hookIncidentes.listarIncidentes()
            setEstaCargando(false)
        })()
    },[])

    useEffect(() => {
        incidenteActualRef.current = incidenteActual
    },[incidenteActual])
    
    useEffect(() => {
        if(verAgenteAsignado){
            eventoAgenteAsignadoRef.current = setInterval(() => {
                mostrarUbicacionAgente(usuarioPosteo,false)
            }, 5000)
        }else{
            clearInterval(eventoAgenteAsignadoRef.current)
        }

        return () => {
            clearInterval(eventoAgenteAsignadoRef.current)
        }
    }, [verAgenteAsignado])

    useEffect(() => {
        if(Object.keys(hookIncidentes.positionNuevaIncidencia).length != 0){
            setMostrarEfectoOndas(true)
        }else{
            setMostrarEfectoOndas(false)
        }
    },[hookIncidentes.positionNuevaIncidencia])

    useEffect(() => {
        if(mostrarEfectoOndas){
            const timer = setInterval(() => {
                setMostrarEfectoOndas(false)
            },5000)
            
            const interval = setInterval(() => {
                if(mostrarEfectoOndas){
                    setWaveRadius((prevRadius) => (prevRadius + 50) % 300);
                }
            }, 200);
            
            if(!mostrarEfectoOndas){    //limpia el timer cuando mostrarefectoondas es false
                clearInterval(interval)
                hookIncidentes.stateIncidentes.googleMap.circulos.map((data,i) => {
                    data.setMap(null)
                })
            }
            return () => {
                clearInterval(timer)
                clearInterval(interval)
            }
        }
    }, [mostrarEfectoOndas]);

    useEffect(() => {
        if(mostrarEfectoOndas){
            hookIncidentes.stateIncidentes.googleMap.circulos.map((data,i) => {    //elimina todos los circulos
                data.setMap(null)
            })
    
            hookIncidentes.stateIncidentes?.googleMap.crearCirculo({
                latLng: {
                    lat:Number(hookIncidentes.positionNuevaIncidencia?.lat),
                    lng:Number(hookIncidentes.positionNuevaIncidencia?.lng)
                },
                radio: waveRadius,
                color: '#FF0000',
                opacidadBorde: 1,
                anchoBorde: 1,
                opacidadFondo: 0,
                colorFondo: ''
            })
        }
    },[mostrarEfectoOndas,waveRadius])

    useEffect(() => {
        if(verMarcadoresCercanos){
            const arrayMarcadores = 
            marcadoresCercanos.map((data) => {
                return hookIncidentes.stateIncidentes.googleMap.crearMarcador({
                    latLng: {lat: Number(data.latitudRegistro), lng: Number(data.longitudRegistro)},
                    icon: {
                        url: '/marcador-verde-2.png',
                        scaledSize: new google.maps.Size(30, 40), // Tamaño del marcador
                        labelOrigin: {
                            x: 15,
                            y: -10
                        }
                    },
                    label: {
                        text: data.codAlerta.toString(), // Texto encima del marcador
                        color: 'black', // Color del texto
                        fontSize: '16px', // Tamaño del texto
                        fontWeight: 'bold', // Opcional: estilo de texto
                    },
                })
            })
            setMarcadoresCercanosReferencias(arrayMarcadores)
        }else{
            marcadoresCercanosReferencias.forEach(data => {
                hookIncidentes.stateIncidentes.googleMap.quitarMarcadorEspecifico(data)
            })
            setMarcadoresCercanosReferencias([])
        }
    }, [verMarcadoresCercanos])

    useEffect(() => {
        const limpiarEfecto = () => {
            clearInterval(eventoMostrarAgentesRef.current)
            eventoMostrarAgentesRef.current = null
            marcadoresAgentesRef.current.forEach(data => hookIncidentes.stateIncidentes.googleMap.quitarMarcadorEspecifico(data))    // QUITA TODOS LOS MARCADORES DE AGENTES
            marcadoresAgentesRef.current = []
        }
        hookIncidentes.stateIncidentes.googleMap.infoWindow && hookIncidentes.stateIncidentes.googleMap.infoWindow.close()  // ELIMINA EL INFOWINDOW SI ESTABA ABIERTO
        if(verAgentes){
            eventoMostrarAgentesRef.current = setInterval(() => {
                crearMarcadoresAgentes(incidenteActualRef.current.codAlerta || null)
            }, (7000));
        }else{
            limpiarEfecto()
        }
        return() => {
            limpiarEfecto()
        }
    },[verAgentes])

    useEffect(() => {
        const ocultarPOI = [
            {
                featureType: "poi",
                stylers: [{ visibility: "off" }]
            }
        ];
        const mostrarPOI = [
            {
                featureType: "poi",
                stylers: [{ visibility: "on" }]
            }
        ];
        if(hookIncidentes.stateIncidentes.googleMap.map){
            hookIncidentes.stateIncidentes.googleMap.map.setOptions({
                styles: verPuntosDeReferencia ? ocultarPOI : mostrarPOI
            });
        }
    },[verPuntosDeReferencia])

    useEffect(() => {
        if(verCamaras){
            crearMarcadoresCamara()
        }else{
            closeModalCamara()
            hookIncidentes.stateIncidentes.googleMap.quitarMarcadorEspecifico(marcadorCamaraRef.current)
            marcadorCamaraRef.current = null
        }
    },[verCamaras])

    useEffect(() => {
        if (Object.keys(stateSocket.payload).length > 0 && hookIncidentes.jurisdiccionMapa) {       // TENER CUIDADO CON EL ERROR QUE APARECE AL CUANDO RECIEN SE ABRE LA PAGINA Y LLEGA UNA ALERTA
            const nuevaAlerta = stateSocket.payload.message.bd
            const pointToCheck = new google.maps.LatLng(nuevaAlerta.latitud,nuevaAlerta.longitud);
            
            const estaDentroDeJurisdiccion = window.google.maps.geometry.poly.containsLocation(pointToCheck, hookIncidentes.jurisdiccionMapa);

            if (estaDentroDeJurisdiccion){
                showNotification(stateSocket.payload.message.bd)
                notify(stateSocket.payload.message.bd.mensajeAlerta || '¡Se envió una alerta!', 'info', { autoClose: true });
                hookIncidentes.listarIncidentes()                
                hookIncidentes.ubicarIncidencia(stateSocket.payload.message.bd,true)
                audioAlerta.current?.play();
            }
        }
    }, [stateSocket.payload])

    useEffect(() => {
        if(hookIncidentes.stateIncidentes.incidentes.length > 0){
            const validar =  setInterval(async () => {
                if(!stateSocket.client.connected){
                    const incidencias = await hookIncidentes.listarIncidenciasTemp()
                    const codigosArreglo1 = hookIncidentes.stateIncidentes.incidentes.map(obj => obj.codAlerta);
                    const codigosArreglo2 = incidencias.map(obj => obj.codAlerta);
                    const tienenMismosCodigos = codigosArreglo1.every(codigo => codigosArreglo2.includes(codigo));
                    if(!tienenMismosCodigos){
                        notify('Llegó una nueva alerta','success');
                        hookIncidentes.listarIncidentes();
                    }
                }
            }, 5000)
            
            return () => {
                clearInterval(validar)
            }
        }
    },[hookIncidentes.stateIncidentes.incidentes])
    const showNotification = async (contentAlert) => {
        const registration = await navigator.serviceWorker?.getRegistration()       // PARECE QUE NUNCA SE EJECUTA
        if(!registration) return console.log("No hay un Service Worker Dash")
    
        registration.showNotification("¡ALERTA!", {
            body: contentAlert.mensajeAlerta || 'Incidente Reportado',
            image: `https://api.plaminoficial.com/api/Minio?nombreCarpetaFoto=${contentAlert.nombreCarpeta}&nombreFoto=${contentAlert.imagenAlerta}`,
            icon: icono,
            data: {
                customData: contentAlert.mensajeAlerta || "Alerta"
            },
        })
    }
    
    const mostrarUbicacionAgente = async (uidPosteo,centrarUbi)  => {
        if(!uidPosteo){
            return
        }
        hookIncidentes.stateIncidentes.googleMap.quitarTodosLosTramos()

        const response = await AuthFetch({
            url: urlBasePlamin + '/user/post?'  + new URLSearchParams({
                uid: uidPosteo,
            }),
        })
        const posteo = response.content

        if((posteo?.latitudUltima == 0 && posteo?.longitudUltima == 0)){
            notify('El agente no posee ubicacion registrada','error')
            hookIncidentes.stateIncidentes.googleMap.quitarTodosLosMarcadoresExcepto(hookIncidentes.marcadorAlerta.current)
            hookIncidentes.stateIncidentes.googleMap.infoWindow && hookIncidentes.stateIncidentes.googleMap.infoWindow.close()
            setVerAgenteAsignado(false)
            hookIncidentes.marcadorPolicia.current = null
            return
        }
        
        hookIncidentes.stateIncidentes.googleMap.quitarMarcadorEspecifico(hookIncidentes.marcadorPolicia.current)
        
        hookIncidentes.marcadorPolicia.current = hookIncidentes.stateIncidentes.googleMap.crearMarcador({
            animation: false,
            visible: true,
            icon: iconoPolicia,
            latLng: {lat: Number(posteo?.latitudUltima) , lng: Number(posteo?.longitudUltima)},
        });

        hookIncidentes.stateIncidentes.googleMap.crearRutaEntreMarcadores(hookIncidentes.marcadorAlerta.current,hookIncidentes.marcadorPolicia.current)

        if(centrarUbi){
            hookIncidentes.stateIncidentes.googleMap.map.setOptions({
                center: {lat: Number(posteo.latitudUltima) , lng: Number(posteo.longitudUltima)},
                zoom: 15
            })
        }
    }

    const asignarUsuarioAlerta = async (uidAsignado) => {
        const response = await AuthFetch({
            url: urlBasePlamin + '/Alerts/assignUser',
            method: 'POST',
            body: JSON.stringify({
                "codAlerta": incidenteActual.codAlerta,
                "uidAsignado": uidAsignado
            })
        })
        notify(response.content,response.isValid ? "success" : "error")
        
        const response2 = await AuthFetch({
            url: urlBasePlamin + '/Alerts/sendAgentAlert',
            method: 'POST',
            body: JSON.stringify({
                "uid": uidAsignado,
                "codAlerta": incidenteActual.codAlerta,
                "nomAlerta": incidenteActual.nomAlertaTipo
            })
        })
        notify(response2.content,response2.isValid ? "success" : "error")

        if(response2.isValid){
            setUsuarioPosteo(uidAsignado)
            mostrarUbicacionAgente(uidAsignado,true)
            setVerAgenteAsignado(true)
            await hookIncidentes.listarIncidentes()
            setVerAgentes(false)
            closeModalAgentes()
        }
    }

    const crearMarcadoresAgentes = async (codigoAlerta) => {    // OBTIENE LOS AGENTES Y DIBUJA LOS MARCADORES
        marcadoresAgentesRef.current.forEach(data => hookIncidentes.stateIncidentes.googleMap.quitarMarcadorEspecifico(data))
        const arrayAgentes = await hookIncidentes.obtenerUbicacionAgentes(codigoAlerta);       // EN CASO EXISTA UNA ALERTA SELECCIONADA MUESTRA LOS AGENTES RESPECTO A LA ALERTA
        setUbicacionesAgentes(arrayAgentes)
        const arregloMarcadores = arrayAgentes.map((data, i) => {   // CREA EL MARCADOR Y RETORNA LAS REFERENCIAS A ELLOS
            const marcadorCreado =
                hookIncidentes.stateIncidentes.googleMap.crearMarcador({
                    animation: false,
                    visible: true,
                    icon: iconoPolicia,
                    latLng: {
                        lat: Number(data.latitudUltima),
                        lng: Number(data.longitudUltima),
                    },
                });
            const infoWindow = googleMap.crearInfowindow({});
            marcadorCreado.addListener("click", () => {
                infoWindow.setContent(`
                    <div>
                        <div style="padding:5px">Nombre usuario: ${data.uid}</div>
                        <div style="padding:5px">Ultimo posteo: ${data.fechaUltimaUbicacion}</div>
                    </div>`);

                infoWindow.open({
                    anchor: marcadorCreado,
                    map: googleMap.map,
                });
            });
            return marcadorCreado
        });
        marcadoresAgentesRef.current = arregloMarcadores
    }
    
    const funcionesAlertasCercanas = {
        esPuntoCercano:  function (punto1, punto2) {
            return (
                (this.calcularDistancia(
                    punto1.latitudRegistro,
                    punto1.longitudRegistro,
                    punto2.latitudRegistro,
                    punto2.longitudRegistro
                ) < 200)
                &&
                (this.calcularDiferenciaMinutos(
                    punto1.fechaHoraAlerta,
                    punto2.fechaHoraAlerta
                ) < 20)
            )
        },
        calcularDistancia: function (lat1, lon1, lat2, lon2) {
            const R = 6371000; // Radio de la Tierra en metros
        
            // Convertir grados a radianes
            const rad = (deg) => deg * (Math.PI / 180);
        
            const dLat = rad(lat2 - lat1); // Diferencia de latitudes en radianes
            const dLon = rad(lon2 - lon1); // Diferencia de longitudes en radianes
        
            const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                      Math.cos(rad(lat1)) * Math.cos(rad(lat2)) *
                      Math.sin(dLon / 2) * Math.sin(dLon / 2);
        
            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        
            return R * c; // Distancia en metros
        },
        calcularDiferenciaMinutos: function(fecha1, fecha2) {
            // Función para convertir una fecha en formato DD/MM/YYYY HH:mm a objeto Date
            function convertirFecha(fechaStr) {
                const [dia, mes, anioHora] = fechaStr.split('/');
                const [anio, horaMinuto] = anioHora.split(' ');
                const [hora, minuto] = horaMinuto.split(':');
                
                return new Date(`${anio}-${mes}-${dia}T${hora}:${minuto}:00`);
            }
        
            const date1 = convertirFecha(fecha1);
            const date2 = convertirFecha(fecha2);
        
            // Calcular la diferencia en milisegundos
            const diferenciaMilisegundos = Math.abs(date2 - date1);
        
            // Convertir la diferencia a minutos (1 minuto = 60,000 milisegundos)
            return Math.floor(diferenciaMilisegundos / (1000 * 60));
        }
    }

    const enviarPorWhatsapp = async (incidencia) => {
        const nombreLugar = await hookIncidentes.stateIncidentes.googleMap.obtenerLugarMarcador({lat: incidencia.latitudRegistro, lng: incidencia.longitudRegistro})
        const url = `https://api.whatsapp.com/send/?text=${encodeURIComponent(incidencia.mensajeAlerta)}|${encodeURIComponent(incidencia.nomAlertaTipo)}:%0a${encodeURIComponent(incidencia.uid)}%0acel: ${encodeURIComponent(incidencia.numCelularPrincipal)}%0adireccion: ${nombreLugar}%0ahttps://maps.google.com/?q=${encodeURIComponent(incidencia.latitudRegistro)},${encodeURIComponent(incidencia.longitudRegistro)}`;
        window.open(url, "_blank");
    };

    const seleccionarAlerta = async (incidencia,indice) => {
        setIncidenteActual(incidencia)
        verAgentes && crearMarcadoresAgentes(incidencia.codAlerta)
        setVerMarcadoresCercanos(false)     // PARA OCULTAR LOS MARCADORES CERCANOS CON CADA SELECCION DE ALERTA
        hookIncidentes.ubicarIncidencia(incidencia)    // UBICA LA INCIDENCIA EN EL MAPA
        setMarcadoresCercanos(  // MOSTRAR AQUI LAS INCIDENCIAS CERCANAS A ESTA INCIDENCIA
            hookIncidentes.stateIncidentes.incidentes.filter(elem => {
                if(JSON.stringify(elem) != JSON.stringify(incidencia)){
                    return funcionesAlertasCercanas.esPuntoCercano(elem, incidencia)
                }
            })
        );
        hookIncidentes.stateIncidentes.googleMap.infoWindow && hookIncidentes.stateIncidentes.googleMap.infoWindow.close();
        if(incidencia.uidAsignado){
            setUsuarioPosteo(incidencia.uidAsignado);
            mostrarUbicacionAgente(incidencia.uidAsignado,true);
            setVerAgenteAsignado(true);
        }else{
            hookIncidentes.stateIncidentes.googleMap.quitarTodosLosTramos()
            setVerAgenteAsignado(false);
        }
    }

    const crearMarcadoresCamara = () => {
        const coordenadasPrueba = {lat: -11.988701729852501,lng: -77.06727178331631}
        marcadorCamaraRef.current = hookIncidentes.stateIncidentes.googleMap.crearMarcador({
            latLng: coordenadasPrueba,
            icon: {
                url: '/icono-camara.png',
                scaledSize: new google.maps.Size(30, 40), // Tamaño del marcador
                labelOrigin: {
                    x: 15,
                    y: -10
                }
            },
        })
        hookIncidentes.stateIncidentes.googleMap.enfocarMarcador(marcadorCamaraRef.current)
        marcadorCamaraRef.current.addListener('click', () => {
            openModalCamara()
        })
    }

    return(
        <>
            <ComponenteCarga variableCarga={estaCargando}></ComponenteCarga>
            <ContenedorParametros titulo="Incidentes" />
            <audio ref={audioAlerta} src={sonidoAlertaActual} />

            {/* MAPA Y BOTONERAS */}
            <div className="relative mapa-incidentes h-[50%]" >
                <MapaAlertas googleMap={hookIncidentes.stateIncidentes.googleMap} />

                <div className='absolute top-2 left-48 h-[45px] flex gap-1'>
                    {/* SOLO APARECE CUANDO AL SELECCIONAR UNA ALERTA SE DETECTA QUE TIENE ALERTAS CERCANAS */}
                    <button 
                        data-tip={verMarcadoresCercanos ? 'Ocultar alertas cercanas' : 'Ver alertas cercanas'}
                        className={`${marcadoresCercanos.length == 0 && 'hidden'} w-[45px] h-full rounded border  p-1 ${verMarcadoresCercanos ? 'bg-gray-400' : 'bg-black'}`}
                        onClick={() => setVerMarcadoresCercanos(!verMarcadoresCercanos)}
                    >
                        <img className='h-full' src={botonAlertasCercanas} />
                    </button><ReactTooltip></ReactTooltip>
                    <button 
                        data-tip={`${verAgentes ? 'Ocultar' : 'Mostrar'} agentes`}
                        className={`w-[45px] h-full rounded border bg-black p-1 ${verAgentes ? 'bg-gray-400' : 'bg-black'}`}
                        onClick={() => {
                            if(verAgentes){
                                closeModalAgentes()
                            }else{
                                crearMarcadoresAgentes(incidenteActual.codAlerta)
                                openModalAgentes()
                            }
                            setVerAgentes(!verAgentes);
                        }}
                    >
                        <img className='h-full' src={botonAgentesActivos} />
                    </button><ReactTooltip></ReactTooltip>
                    <button 
                        data-tip={`${verPuntosDeReferencia ? 'Mostrar' : 'Ocultar'} puntos de referencia`}
                        className={`w-[45px] h-full rounded border bg-black p-1 ${verPuntosDeReferencia ? 'bg-gray-400' : 'bg-black'}`}
                        onClick={() => setVerPuntosDeReferencia(!verPuntosDeReferencia)}
                    >
                        <img className='h-full' src={botonVerMapa} />
                    </button><ReactTooltip></ReactTooltip>
                    <button 
                        data-tip={`${verCamaras ? 'Ocultar' : 'Mostrar'} cámara`}
                        className={`w-[45px] h-full rounded border bg-black p-1 ${verCamaras ? 'bg-gray-400' : 'bg-black'}`}
                        onClick={() => setVerCamaras(!verCamaras)}
                    >
                        <img className='h-full' src={botonCamarasDisponibles} />
                    </button>
                </div>
            </div> 

            {/* TABLA DE ALERTAS */}
            <div className="containerScroll tabla-incidentes max-h-[40%] overflow-auto" >
                <table className="table">
                    <thead>
                        <tr>
                            <th className=" w-[15px]">N°</th>
                            <th className=" w-[15px]">TICKET</th>
                            <th className=" w-[150px]">FECHA HORA</th>
                            <th className=" w-[100px]">INCIDENCIA</th>
                            <th className=" W-[40%]">COMENTARIO</th>
                            <th className=" w-[150px]">DIRECCION</th>
                            <th className=" w-[150px]">USUARIO</th>
                            <th className=" w-[100px]">DNI</th>
                            <th className=" w-[100px]">CELULAR</th>
                            <th className=" w-[200px]">SEGUIMIENTO</th>
                            <th className=" w-[15px]">ESTADO</th>
                            <th className=" w-[100px]">ASIGNADO</th>
                            <th className=" w-[15px]">FOTO</th>
                            {/* <th className=" w-[15px]"></th> */}
                            <th className=" w-[15px]"></th>
                            <th className=" w-[15px]"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            hookIncidentes.stateIncidentes.incidentes.length > 0 ?
                            hookIncidentes.stateIncidentes.incidentes.map((incidencia, i) => {
                                return (
                                    <tr 
                                        key={i+1} 
                                        className={`${incidenteActual.codAlerta == incidencia.codAlerta ? '!bg-[#264258]' : ''}`} 
                                        onClick={() => seleccionarAlerta(incidencia,i)}
                                    >
                                        <td className="text-center">{i+1}</td>
                                        <td className="text-center">{incidencia.codAlerta}</td>
                                        <td className="text-center">{incidencia.fechaHoraAlerta}</td>
                                        <td className="text-center">{incidencia.nomAlertaTipo}</td>
                                        <td className="text-left">{incidencia.mensajeAlerta}</td>
                                        <td className="text-center"><div title={incidencia.direccion || '-'} className='max-w-[150px] whitespace-nowrap overflow-hidden text-ellipsis'>{incidencia.direccion || '-'}</div></td>
                                        <td className="text-center">{incidencia.uid }</td>
                                        <td className="text-center">{incidencia.numeroDocumento}</td>
                                        <td className="text-center">{incidencia.numCelularPrincipal}</td>
                                        <td className="text-center w-[200px]">
                                            <div className='gap-1 flex max-w-[200px] overflow-x-auto containerScrollSeguimiento'>
                                                {incidencia.cantidadAlertaAtencion > 0 
                                                    ?   Array.from({ length: incidencia.cantidadAlertaAtencion }, (_, index) => (
                                                            <button
                                                                key={index}
                                                                className='bg-blue-500 w-[25px] h-[25px] rounded text-xs hover:bg-blue-300'
                                                                onClick={() => {
                                                                    hookSeguimientoAlertas.editarValorSeguimientoAlerta('codEstadoIntensidadAlerta', '');
                                                                    hookSeguimientoAlertas.editarValorSeguimientoAlerta('chkEstadoAlerta', false);
                                                                    hookSeguimientoAlertas.listarSeguimientoAlerta(incidencia.codAlerta);
                                                                }}
                                                            >
                                                                {index + 1}
                                                            </button>
                                                        )) 
                                                    :   <></>
                                                }
                                                {
                                                    incidencia.codEstado == 7       // ELIMINADO
                                                    ?   <></>
                                                    :   <button
                                                            className='bg-green-500 w-[25px] h-[25px] rounded text-xs hover:bg-green-300'
                                                            onClick={() => {
                                                                hookSeguimientoAlertas.editarValorSeguimientoAlerta('codEstadoIntensidadAlerta', '');
                                                                hookSeguimientoAlertas.editarValorSeguimientoAlerta('chkEstadoAlerta', false);
                                                                hookSeguimientoAlertas.listarSeguimientoAlerta(incidencia.codAlerta);
                                                            }}
                                                        >
                                                            <i className='fa fa-plus'/>
                                                        </button>
                                                }
                                            </div>
                                        </td>
                                        <td>
                                            <div className={`${incidencia.codEstado == 9 ? 'bg-orange-600' : incidencia.codEstado == 45 ? 'bg-green-600' : incidencia.codEstado == 7 ? 'bg-red-600' : 'bg-white'} text-xs rounded py-[2px] whitespace-nowrap w-[80px] text-center`}>{primeraLetraMayuscula(incidencia.nomEstado)}</div>
                                        </td>
                                        <td className="text-center">{incidencia.uidAsignado}</td>
                                        <td className="text-center">
                                            {
                                                incidencia.imagenAlerta 
                                                ?   <button 
                                                        className="fa fa-camera text-white-600 text-lg botonFoto" 
                                                        onClick={()=> verFoto(incidencia.nombreCarpetaAlerta,incidencia.imagenAlerta)}
                                                    ></button> 
                                                :   <></>
                                            }
                                        </td> 
                                        <td >
                                            {
                                                incidenteActual.codAlerta == incidencia.codAlerta
                                                ?   <>
                                                        <button 
                                                            data-tip='Compartir ubicación' 
                                                            className="fas fa-share-alt text-green-600 text-lg" 
                                                            onClick={() => enviarPorWhatsapp(incidencia)}
                                                        ></button><ReactTooltip></ReactTooltip>
                                                    </>
                                                :   <></>
                                            }
                                        </td>
                                        <td >
                                            {
                                                incidenteActual.codAlerta == incidencia.codAlerta
                                                ?   <>
                                                        <button  
                                                            data-tip='Asignar agente' 
                                                            className="fas fa-user text-orange-500 text-lg"
                                                            onClick={ () => {
                                                                crearMarcadoresAgentes(incidenteActual.codAlerta)
                                                                setVerAgentes(true)
                                                                openModalAgentes()
                                                            }}
                                                        ></button><ReactTooltip></ReactTooltip>
                                                    </>
                                                :   <></>
                                            }
                                        </td>
                                    </tr>
                                )
                            })
                            :<tr ><td colSpan='16' className='text-center'>No hay información para mostrar con los parametros seleccionados</td></tr>
                        }
                    </tbody>
                </table>
            </div>

            {/* MODAL DE FOTO */}
            <Modal 
                isOpen={isOpenFoto} 
                closeModal={closeModalFoto}
                action={closeModalFoto} 
                title={`Foto [Ticket: ${incidenteActual.codAlerta}]`}
                textButtons = {{
                    confirm: ''
                }}
                validButton = {{
                    confirm: false,
                    denied: false
                }}        
            >
                <div className='flex-col h-[500px]'>


                <div 
                    className={`z-0 rounded w-[450px] border border-gray-700 h-[400px] relative mt-1 containerScroll overflow-y-auto overflow-x-auto flex justify-center items-center `}
                    style={{ width: '400x', height: '535px', textAlign:'center'}}
                >
                    <img 
                        src={fotoUrl} 
                        className={` rounded h-[100%] `}
                        // style={{ transform: `rotate(${rotacion}deg)` }}
                        style={{
                            // width: '100%',
                            // height: '100%',
                            transform: `rotate(${rotacion}deg) scale(${zoom})`,
                            // transform: `rotate(${rotacion}deg) scale(${zoom}) translate(${dragOffset.x}px, ${dragOffset.y}px)`,
                            position: 'relative',
                            top: `${dragOffset.y}px`,
                            left: `${dragOffset.x}px`,
                            
                            transition: 'transform 0.3s',
                            transformOrigin: 'center',

                            overflow: 'hidden' // Añadir overflow: hidden para recortar cualquier parte sobresaliente de la imagen
                        }}
                        alt="sin imagen" 
                        onMouseDown={(e) => {
                            if (e.button === 2) {
                                setRotacion(0);
                                setZoom(1);
                                setDragOffset({ x: 0, y: 0 });
                            } else {
                                setIsDragging(true);
                                setDragStart({ x: e.clientX, y: e.clientY });
                            }
                        }}
                        onMouseMove={(e) => {
                            if (isDragging) {
                                const x = e.clientX - dragStart.x + dragOffset.x;
                                const y = e.clientY - dragStart.y + dragOffset.y;
                                setDragOffset({ x, y });
                            }
                        }}
                        onMouseUp={() => {
                            setIsDragging(false);
                            setDragStart({ x: 0, y: 0 });
                        }}
                        onDoubleClick={() => {
                            setRotacion(0);
                            setZoom(1);
                            setDragOffset({ x: 0, y: 0 });
                        }} // Doble clic para restablecer la posición
                        onMouseLeave={() => {
                            setIsDragging(false);
                            setDragStart({ x: 0, y: 0 });
                        }} // Manejar el soltar el botón del mouse si se sale de la imagen
                    />
                </div>

                <div className=' text-center mt-1'>

                    
                    <button 
                        className=' right-0 ml-2 bottom-[90px] w-[28px] bg-green-800 hover:bg-green-700 text-xl rounded' 
                        // className={`flex rounded h-full w-full transform rotate-${rotacion}`}
                        
                        style={{zIndex:'100000'}}
                        onClick={() => {
                            if (zoom === 1) {
                                setZoom(1.2);
                            } else if (zoom === 1.2) {
                                setZoom(1.5);
                            }else if (zoom ===1.5){
                                setZoom(1.8);
                            }else if (zoom ===1.8){
                                setZoom(2.0);
                            }else if (zoom ===2.0){
                                setZoom(2.5);
                            }else if (zoom ===2.5){
                                setZoom(3.0);
                            } else {
                                setZoom(1);
                            }
                        }}
                        >
                        <i 
                            data-tip 
                            data-for='toolZoomFoto' 
                            className="fa fa-search-plus" 
                            />

                        <Tooltip
                            id='toolZoomFoto' 
                            >Zoom x{zoom}
                        </Tooltip>
                    </button>

                    <button 
                        className='ml-2 right-0 bottom-[60px] w-[28px] bg-green-800 hover:bg-green-700 text-xl rounded' 
                        // className={`flex rounded h-full w-full transform rotate-${rotacion}`}
                        
                        style={{zIndex:'100000'}}
                        onClick={() => setRotacion((rotacion + 90) % 360)}
                        >
                        <i 
                            data-tip 
                            data-for='toolGirarFoto' 
                            className="fa fa-retweet" 
                            />
                        <Tooltip
                            id='toolGirarFoto' 
                            >Girar
                        </Tooltip>
                    </button>

                    <button 
                        data-tip 
                        data-for='toolRedireccionarFoto' 
                        className=' right-0 bottom-0 ml-2 bg-blue-500 hover:bg-blue-700 text-xl rounded' 
                        style={{zIndex:'100000'}}
                        >
                        <a  
                            className="m-1 fa fa-share" 
                            target="_blank" 
                            href={`${fotoUrl}`} 
                            rel="noopener noreferrer" 
                            >
                        </a>
                        <Tooltip id='toolRedireccionarFoto' >Abrir Foto</Tooltip>
                    </button>

                    </div>


                </div>
            </Modal>

            {/* MODAL DE SEGUIMIENTO Y CALIFICACION */}
            <Modal 
                    isOpen={hookSeguimientoAlertas.isOpenModalSeguimiento}
                    textButtons = {{confirm: 'Guardar', denied: 'Cerrar'}}
                    validButton = {{ confirm:  hookSeguimientoAlertas.seguimientoAlerta?.chkEstadoAlerta? true : false , denied: true}}
                    action={() => {
                        hookSeguimientoAlertas.closeModalSeguimiento()
                        hookSeguimientoAlertas.finalizarAlerta()
                        hookIncidentes.listarIncidentes()
                    }}
                    closeModal={() => {
                        hookSeguimientoAlertas.closeModalSeguimiento()
                        hookIncidentes.listarIncidentes()
                    }} 
                    title={`Ticket: ${incidenteActual.codAlerta}`}
                >
                    <div className="flex items-center  flex-wrap justify-center " >
                        <div className="w-[700px] px-4 flex flex-col justify-between form-content overflow-auto containerScroll">
                            <div className="containerScroll flex flex-col justify-between w-full form-content overflow-auto	max-h-55 lg:w-[320px]">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th className='w-[5px] text-left'>N°</th>
                                            <th className='w-[130px] text-center'>FECHA</th>
                                            <th className='w-[40%] text-left'>DESCRIPCIÓN</th>
                                            <th className='w-[100px] text-center'>USUARIO</th>
                                            <th className='w-[50px] text-center'>OPCIONES</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            hookSeguimientoAlertas.seguimientoAlertas.length == 0
                                            ?   <></>
                                            :   hookSeguimientoAlertas.seguimientoAlertas.map((s,i) => {
                                                    return (
                                                        <tr key={i+1}>
                                                            <td className='text-center border border-gray-500'>{++i}</td>
                                                            <td className='text-center border border-gray-500'>{s.fechaHoraRegistro}</td>
                                                            <td className='text-left border border-gray-500'>{s.mensajeAlertaAtencion}</td>
                                                            <td className='text-center border border-gray-500'>{s.uidRegistro}</td>
                                                            <td className='w-[5px] border text-center border-gray-500'> 
                                                                {
                                                                    (i == hookSeguimientoAlertas.seguimientoAlertas.length) && (incidenteActual.codEstado != 7)
                                                                    ?   <button 
                                                                            className='px-1 py-1  rounded hover:bg-[#931E0C] ' 
                                                                            onClick={()=>hookSeguimientoAlertas.eliminarSeguimiento(s.codAlertaAtencion)}
                                                                        >
                                                                            <i className={`text-red-500 fa fa-trash-can text-[15px]`}></i>
                                                                        </button>
                                                                    :   <></>
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                        }
                                        {
                                            incidenteActual.codEstado == 7      // ELIMINADO
                                            ?   <></>
                                            :   <tr className='border border-gray-500 '>
                                                    <td className='border border-gray-500 text-center'>
                                                        {hookSeguimientoAlertas.seguimientoAlertas?.length + 1 }
                                                    </td>
                                                    <td colSpan={3} style={{height:'35px'}}>
                                                        <input className={`${classNameInput} `}
                                                            value={hookSeguimientoAlertas.seguimientoAlerta.mensajeAlertaAtencion} 
                                                            onChange={(e) => {hookSeguimientoAlertas.editarValorSeguimientoAlerta("mensajeAlertaAtencion", e.target.value)}}
                                                            autoFocus={true}
                                                        />
                                                    </td>
                                                    <td className='text-center'>
                                                        <button 
                                                            className='fa fa-angle-right rounded h-6 text-[18px] bg-green-500 px-3 py-1'
                                                            onClick={()=> {
                                                                hookSeguimientoAlertas.guardarSeguimientoAlerta();hookSeguimientoAlertas.editarValorSeguimientoAlerta("codAlerta", incidenteActual.codAlerta);
                                                                hookSeguimientoAlertas.editarValorSeguimientoAlerta("mensajeAlertaAtencion", "")}
                                                            }
                                                        >
                                                        </button>
                                                    </td>
                                                </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className={`mt-[-15px] relative text-right max-w-full px-5 ${incidenteActual.codEstado == 7 ? '!hidden' : ''}`}>
                        <label className='mr-2 '>Finalizar ticket</label><label className="switch top-2">
                            <input className='' type="checkbox" checked={hookSeguimientoAlertas.seguimientoAlerta.chkEstadoAlerta} onChange={(e) => hookSeguimientoAlertas.editarValorSeguimientoAlerta("chkEstadoAlerta",e.target.checked)} />
                            <div className="slider"></div>
                        </label>
                    </div>

                    <div className='flex items-center flex-wrap justify-center relative mt-[20px]' style={{display: hookSeguimientoAlertas.seguimientoAlerta.chkEstadoAlerta ? '' : 'none'}}> 
                        <h2 className='absolute underline top-0 text-xl'>
                            <span ><i className='fas fa-star text-[#F4FF0B]'></i>Califique el ticket<i className='fas fa-star text-[#F4FF0B]'></i></span>
                        </h2>    

                        <div className='rounded flex gap-8 items-center relative justify-center mt-[30px]'>
                                <div className='m-[5px]'>
                                    <form action="" className='formularioRadio'>
                                        <div className="radio relative lg:max-w-[150px] ">
                                                {
                                                    hookCalificacionAlerta.calificaciones.map((c,i) => {
                                                        return (
                                                            <React.Fragment 
                                                                key={i+1}>
                                                                    <input 
                                                                        type='radio' name="calificacion" 
                                                                        value={c.codEstadoIntensidadAlerta} 
                                                                        defaultChecked={hookSeguimientoAlertas.seguimientoAlerta.codCalificacion === c.codEstadoIntensidadAlerta ? true : false} 
                                                                        id={c.nomEstado}
                                                                        onChange={(e) => {
                                                                            hookSeguimientoAlertas.editarValorSeguimientoAlerta("codEstadoIntensidadAlerta",e.target.value)}
                                                                        }>
                                                                    </input>
                                                                    <label 
                                                                        className='' 
                                                                        htmlFor={c.nomEstado} 
                                                                    >
                                                                            {(c.nomEstado)}
                                                                    </label>
                                                            </React.Fragment>
                                                        )
                                                    })
                                                }
                                        </div>
                                    </form>
                                </div>
                        </div>
                    </div>
            </Modal>

            {/* MODAL DE ASIGNACION */}
            <ModalAsignacion
                isOpen={isOpenModalAgentes} 
                closeModal={() => {
                    hookIncidentes.stateIncidentes.googleMap.infoWindow && hookIncidentes.stateIncidentes.googleMap.infoWindow.close()
                    hookIncidentes.stateIncidentes.googleMap.quitarTodosLosMarcadoresExcepto(hookIncidentes.marcadorAlerta.current)
                    hookIncidentes.stateIncidentes.googleMap.quitarTodosLosTramos()
                    setVerAgentes(false)    // CUANDO CIERRA EL MODAL DESHABILITA LA OPCION DE VER AGENTES
                    closeModalAgentes()
                }}
                title={`Asignar agente [Ticket: ${incidenteActual.codAlerta}]`}
                textButtons = {{
                    confirm: 'Ver agentes cercanos'
                }}
                validButton = {{ confirm: false , denied: false}}
            >
                <div className=" containerScroll tabla-incidentes lg:max-w-[320px] max-h-[300px] w-[400px] overflow-auto" >
                    <table className="table">
                        <thead>
                            <tr>
                                <th className="w-[15px]"></th>
                                <th className="w-[100px]">NOMBRE</th>
                                {
                                    incidenteActual.codAlerta
                                    ?   <th className="w-[80px]">DISTANCIA</th>
                                    :   <></>
                                }
                                <th className="w-[150px]">FECHA</th>
                                <th className="w-[15px]"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                ubicacionesAgentes?.length > 0 ?
                                ubicacionesAgentes?.map((data, i) => (
                                        <tr 
                                            key={i+1} 
                                            onClick={() => mostrarUbicacionAgente(data.uid,true)}
                                        >
                                            <td className="text-center">
                                                <i className={`fas fa-circle ${data.fechaUltimaUbicacion ? 'text-green-600' : 'text-red-600'}`}></i>
                                            </td>
                                            <td className="text-center">{data.uid}</td>
                                            {
                                                incidenteActual.codAlerta 
                                                ?   <td className="text-center whitespace-nowrap">{data.distanciaAgente || '-'} mtrs</td>
                                                :   <></>
                                            }
                                            <td className="text-center">{data.fechaUltimaUbicacion}</td>
                                            <td className="text-center">
                                                <button 
                                                    data-tip="Asignar agente"
                                                    className=" fas fa-check text-gray-500 hover:text-green-300 text-lg" 
                                                    onClick={() => asignarUsuarioAlerta((data.uid))}
                                                ></button><ReactTooltip></ReactTooltip>
                                            </td>
                                        </tr>
                                ))
                                :<tr ><td colSpan='6' className='text-center'>No hay información para mostrar con los parametros seleccionados</td></tr>
                            }
                        </tbody>
                    </table>
                </div>
            </ModalAsignacion>

            <Modal isOpen={isOpenModalCamara} closeModal={closeModalCamara} textButtons={{denied: 'Cerrar'}} validButton={{denied:true}} title={'Cámara [ABX]'}>
                <div className='w-[500px] relative'>
                    <img src={camaraSinConexion} className='w-full' alt="" />
                    <span className='absolute top-[50%] left-[50%] text-red-600 font-bold text-xl -translate-x-1/2 -translate-y-1/2'>IMAGEN NO DISPONIBLE</span>
                </div>
            </Modal>
        </>
    )
}