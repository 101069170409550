import React from 'react';
// import '../../assets/css/views/login.css'
import '../../assets/css/views/estiloSlider.css'
import img1 from '../../assets/images/PoliciaNacionalPeru.png';
import img2 from '../../assets/images/MunicipalidadPeru.png';
import img3 from '../../assets/images/CuerpoBomberosPeru.png';
import btn1 from '../../assets/images/PlaminLogo.png';
import btn2 from '../../assets/images/IconoCentroMonitoreo.fw.png'
import btn3 from '../../assets/images/IconoAutoridad.fw.png';
import btn4 from '../../assets/images/IconoCenPoblado.fw.png';
import logo from '../../assets/images/LogoAbexa2018.png'

import {Slideshow, Slide } from '../components/utils/SliderShow'
import { notify } from '../../utils/utils';

import {
    useHistory
} from "react-router-dom";

const generatePKCE = async () => {
	const array = new Uint8Array(32);
	window.crypto.getRandomValues(array);
	const codeVerifier = btoa(String.fromCharCode(...array))
		.replace(/\+/g, "-")
		.replace(/\//g, "_")
		.replace(/=+$/, "");

	const encoder = new TextEncoder();
	const data = encoder.encode(codeVerifier);
	const hashBuffer = await window.crypto.subtle.digest("SHA-256", data);

	const hashArray = Array.from(new Uint8Array(hashBuffer));
	const base64 = btoa(String.fromCharCode(...hashArray))
		.replace(/\+/g, "-")
		.replace(/\//g, "_")
		.replace(/=+$/, "");

	return { codeVerifier, codeChallenge: base64 };
};

export const Web = () => {
 
    const iniciarSesionConAbexa = async () => {
        const result = await generatePKCE();
		localStorage.setItem("pkce", JSON.stringify(result))
        
        const queryParameters = new URLSearchParams({
            response_type: "code",
            client_id: process.env.REACT_APP_WSO2IS_CLIENT_ID,
            redirect_uri: process.env.REACT_APP_REDIRECT_URI,
            scope: process.env.REACT_APP_WSO2IS_SCOPES,
            state: "ThisIsMyStateValue",
            code_challenge: result.codeChallenge,
			code_challenge_method: 'S256',
            id_carga: Date.now()
        })

        const urlRedirect = `${process.env.REACT_APP_WSO2IS_AUTHORIZATION}/authorize?${queryParameters}`
        
        try {
            window.location.href = urlRedirect;
        } catch (error) {
            console.error('Error en la solicitud:', error);
        }
    }   

    
    
    
    const inciciarSesionConAbexaCentroPoblado = async () => {
        const urlCentrosWeb = `https://cenpoblado.plaminoficial.com/`

        try {
            window.location.href = urlCentrosWeb;
        } catch (error) {
            console.error('Error en la solicitud:', error);
        }
    }   


    

    const history = useHistory();

    return (
        <div className="flex flex-col justify-between h-screen">
            <div className="h-[56px] flex items-center justify-start lg:justify-center bg-[#002A78]">
                <div className="flex-shrink-0 flex justify-center items-center w-[250px]">
                    <img className="w-[90px] h-full block sm:w-52 w-auto" src={logo} alt="Workflow"/>
                </div>
            </div>
            <div className="grow dark:bg-slate-900 lg:h-[50%] ">
                <main className=' lg:w-[85%]'>
                    <Slideshow controles={true} autoplay={true} velocidad="1500" intervalo="4000">
                        <Slide>
                            <a href="">
                                <img src={img1} alt=""/>
                            </a>
                           
                        </Slide>
                        <Slide>
                            <a href="">
                                <img src={img2} alt=""/>
                            </a>
                            
                        </Slide>
                        <Slide>
                            <a href="">
                                <img src={img3} alt=""/>
                            </a>
                            
                        </Slide>

                    </Slideshow>
                </main>
                
                <div className="container flex justify-center items-center lg:h-[70%]">
                    <div className='flex flex-col  text-center ' >
                        <div className='text-2xl text-gray-200 flex justify-center text-center border-gray-500 border-b'>
                            Iniciar Sesión.
                        </div>
                        <div className='flex lg:flex-col '>
                            <div 
                                className='lg:w-[100px] dark:text-white items-center w-[120px] m-[30px] lg:m-[10px] text-center cursor-pointer' 
                                onClick={() => iniciarSesionConAbexa()}
                            >
                                <img className=' boton-web rounded-2xl' src={btn1} alt='' /><label className='text-center'>Administrador</label>
                            </div>

                            <div 
                                className='lg:w-[100px] dark:text-white items-center w-[120px] m-[30px] lg:m-[10px] text-center cursor-pointer' 
                                onClick={() => iniciarSesionConAbexa()}
                            >
                                <img className=' boton-web rounded-2xl' src={btn2} alt='' /><label>C.C.M.</label>
                            </div>

                            <div 
                                className='lg:w-[100px] dark:text-white items-center w-[120px] m-[30px] lg:m-[10px] text-center cursor-pointer'
                                onClick={() => iniciarSesionConAbexa()}
                            >   
                                <img className='boton-web rounded-2xl' src={btn3} alt=''/><label>Autoridad</label>
                            </div>
                            <div 
                                className='lg:w-[100px] dark:text-white items-center w-[120px] m-[30px] lg:m-[10px] text-center cursor-pointer'
                                onClick={() => inciciarSesionConAbexaCentroPoblado()}
                            >   
                                <img className='boton-web rounded-2xl' src={btn4} alt=''/><label>CenPoblado</label>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="dark:text-white footer lg:text-[12px]  bg-[#002A78] h-[60px]">
                <footer className='flex items-center justify-between  h-full'>
                    <div className='text-left ml-12 lg:ml-2 lg:text-center '>
                        <label> Copyright Abexa Cloud Company 2018</label>
                    </div>
                    <div className='text-right mr-12 lg:mr-2 lg:text-center '>
                        <label> Politica de privacidad Terminos de uso</label>
                    </div>
                </footer>
            </div>
        </div>
    )
}
