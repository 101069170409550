import React, { createContext, useReducer, useEffect, useState} from "react";
import { SocketReducers } from '../reducers/SocketReducers'
import mqtt from 'mqtt/dist/mqtt';

export const SocketContext = createContext();

export function SocketProvider(props) {
    const [ subscription, setSubscription ] = useState(false);

    const initialState = {
        connectStatus: localStorage.getItem('connect-status') || 'Connect',
        client: false, //JSON.parse(localStorage.getItem('client')) || false,
        isSubed: false,
        payload: {}
    }

    const [ state, dispatch ] = useReducer(SocketReducers, initialState);

    useEffect(() => {
        if (state.connectStatus === 'Connecting' || state.client) return; // Evita múltiples conexiones
        if (state.connectStatus === 'Disconnected' || state.connectStatus === 'Reconnecting') {
            mqttConnect();
        }
    }, [state.connectStatus])

    useEffect(() => {
        if (!state.client) return; // Si no hay cliente, salir

        const handleConnect = () => {
            dispatch({ type: 'setConnectStatus', payload: 'Connected' });
        };

        const handleError = (err) => {
            console.error('Connection error: ', err);
            state.client.end();
        };
    
        const handleReconnect = () => {
            dispatch({ type: 'setConnectStatus', payload: 'Reconnecting' });
        };
    
        const handleMessage = (topic, message) => {
            const payload = { topic, message: JSON.parse(message.toString()) };
            dispatch({ type: 'setPayload', payload });
        };

        state.client.on('connect', handleConnect);
        state.client.on('error', handleError);
        state.client.on('reconnect', handleReconnect);
        state.client.on('message', handleMessage);

        return () => {
            state.client.removeListener('connect', handleConnect);
            state.client.removeListener('error', handleError);
            state.client.removeListener('reconnect', handleReconnect);
            state.client.removeListener('message', handleMessage);
        };
    }, [state.client]);

    useEffect(() => {
        if (subscription) {
            mqttSub(subscription)
        }
    }, [subscription])

    const mqttConnect = () => {
        console.log("Entro al mqttConnect")
        if (state.client) {
            state.client.end(); // Cierra el cliente anterior si existe
        }
        const host = process.env.REACT_APP_MQTT_HOST;
        // const port = process.env.REACT_APP_MQTT_PORT;
        const protocol = process.env.REACT_APP_MQTT_PROTOCOL;
        const url = `${protocol}://${host}/mqtt`
        // const url = `wss://mosquitto-websocket.abexa.pe/mqtt`;
        
        const options = {
            keepalive: 30,
            protocolId: 'MQTT',
            protocolVersion: 4,
            clean: true,
            reconnectPeriod: 1000,
            connectTimeout: 30 * 1000,
            rejectUnauthorized: false,
            username: 'admin',
            password: 'admin',
            clientId: `plamin.+ ${Math.random().toString(16).substr(2, 8)}`
        };
        
        // options.clientId = `plamin.+ ${Math.random().toString(16).substr(2, 8)}`;

        dispatch({ type: 'setConnectStatus', payload: 'Connecting' });
        const newClient = mqtt.connect(url, options);

        dispatch({ type: 'setClient', payload: newClient}); 
    }

    const mqttDisconnect = () => {
        if (state.client) {
            state.client.end(() => {
                dispatch({ type: 'setConnectStatus', payload: 'Disconnect' });
            });
        }
    }
    
    const mqttPublish = (context) => {
        if (state.client) {
            const { topic, qos, payload } = context;
            state.client.publish(topic, payload, { qos }, error => {
                if (error) {

                }
            });
        }
    }
    
    const mqttSub = (subscription) => {
        console.log("ENTRO AL MQTT SUB ", subscription)
        if (state.client) {
            const { topic, qos } = subscription;
            state.client.subscribe(topic, { qos }, (error) => {
                if (error) {

                    return
                }
                dispatch({ type: 'setIsSub', payload: true });

            });
        }
    };
    
    const mqttUnSub = (subscription) => {
        if (!state.client) return;
        const { topic } = subscription;
        state.client.unsubscribe(topic, error => {
            if (error) {

                return
            }
            dispatch({ type: 'setIsSub', payload: false });
        });
    };

    useEffect(() => {
        const completarConCeros = (str) => {
            str = String(str);
            const cerosFaltantes = 10 - str.length;
            return cerosFaltantes > 0 ? '0'.repeat(cerosFaltantes) + str : str;
        };

        const checkCodEntidad = setInterval(() => {
            const codEntidad = localStorage.getItem('codEntidad');
            if(!state.client){
                clearInterval(checkCodEntidad); // Detener el intervalo cuando se obtiene un UID válido
            }
            if (codEntidad) {
                clearInterval(checkCodEntidad); // Detener el intervalo cuando se obtiene un UID válido

                const qos = 0;
                const topic = `ABX/BS/PL/${completarConCeros(codEntidad)}/RQ`;
    
                setSubscription({ topic, qos } || false)
            }
        }, 1000); // Verifica cada segundo
    
        return () => clearInterval(checkCodEntidad); // Limpia el intervalo al desmontar
    },[])

    return (
        <SocketContext.Provider value={{ stateSocket: state, mqttConnect, mqttDisconnect, mqttPublish, mqttSub, mqttUnSub }}>
            { props.children }
        </SocketContext.Provider>
    )
}